import H2TrapeziumBG from "../../../_hoc/H2TrapeziumBG";
import { IMemberOfComponentProps } from "../../../lib/types/components";
import { dynamicCssSize } from "../../../lib/utils/constants/PagesConstants";
import CustomImageComponent from '../CustomImage/CustomImageComponent';

const MemberOfComponentV2 = (props: IMemberOfComponentProps) => {

    const {membersData} = props;

    return (
        membersData && Object.keys(membersData).length ?
            <H2TrapeziumBG uniqueContainerId={"memberOf_container"}>
                <style jsx>{`
                  .memberContainerV2 {
                    background: linear-gradient(300deg, rgba(115, 12, 156, 1) 0%, rgba(53, 37, 90, 1) 100%), url("https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/trusted_bg.webp");
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-blend-mode: color;
                    padding: 18px 0px 4.6vw;
                    font-family: var(--font-primary);
                    color: #ffffff;
                    position: relative;
                    z-index: -2;
                  }

                  .headingParent {
                    display: flex;
                    justify-content: center;
                  }

                  .heading {
                    font-size: ${dynamicCssSize(60, 16)};
                    text-transform: uppercase;
                    font-weight: 900;
                    text-transform: uppercase;
                    font-style: italic;
                    letter-spacing: 2.25px;
                    max-width: 65vw;
                    text-align: center;
                  }

                  .contentContainer {
                    display: flex;
                    width: 100%;
                    margin: ${dynamicCssSize(50, 10)} 0;
                  }

                  .memberOfContainer {
                    position: relative;
                  }

                  .imageParent {
                    position: absolute;
                    top: 20%;
                    left: -5%;
                  }

                  .imageParent::before {
                    content: "";
                    box-shadow: 0px 0px 350px 150px #8e1b6c;
                    position: absolute;
                    z-index: -1;
                    top: 50%;
                    left: 50%;
                  }
                  
                  .imageParent:nth-child(1) {
                    backdrop-filter: drop-shadow(3px 5px 17px rgba(0, 0, 0, 0.14));
                  }

                  .imageParent:nth-child(2) {
                    top: 40%;
                    left: 25%;
                  }

                  .imageContainer {
                    position: relative;
                    width: ${dynamicCssSize(605, 220)};
                    height: ${dynamicCssSize(392, 176)};
                  }

                  .featureItemParent {
                    display: flex;
                    flex-direction: column;
                    padding: 0px 0 35px ${dynamicCssSize(100, 0)};
                    height: 100%;
                    transform: skewX(-15deg);
                    border-left: 10px solid;
                    border-image-slice: 1;
                    border-image-source: linear-gradient(180deg, rgba(224, 128, 0, 1) 0%, rgba(252, 216, 0, 1) 40%, rgba(252, 216, 4, 1) 41%, rgba(253, 234, 41, 1) 48%, rgba(254, 246, 67, 1) 54%, rgba(255, 253, 82, 1) 60%, rgba(255, 255, 88, 1) 64%, rgba(224, 202, 37, 1) 78%, rgba(201, 162, 0, 0.06) 100%);
                  }

                  .featureItemContainer {
                    display: flex;
                    align-items: top;
                    height: calc(100% / 3);
                    padding: ${dynamicCssSize(80, 0)} 0px;
                  }

                  .itemImageParent {
                    position: absolute;
                    left: -13%;
                    transform: skewX(15deg);
                  }

                  .itemImageContainer {
                    position: relative;
                    width: ${dynamicCssSize(125, 50)};
                    height: ${dynamicCssSize(125, 50)};
                  }

                  .featureItem {
                    font-size: ${dynamicCssSize(48, 16)};
                    font-weight: 600;
                    font-style: italic;
                    letter-spacing: -0.77px;
                    color: rgba(255, 255, 255, 0.85);
                    transform: skewX(15deg);
                  }

                  @media screen and (max-width: 1200px) {
                    .itemImageParent {
                      left: -10%;
                    }
                  }

                  @media screen and (max-width: 767px) {
                    .heading {
                      letter-spacing: 1px;
                    }

                    .featureItemParent {
                      border: none;
                      flex-direction: row;
                      transform: skewX(0deg);
                      padding: 30px 0px 0px;
                    }

                    .memberOfContainer {
                      min-height: 100px;
                    }

                    .imageParent {
                      top: -20%;
                      left: 50%;
                      transform:translateX(-50%);
                    }
                    
                    .imageParent::before{
                      box-shadow: -50px 0px 75px 35px #8e1b6c;
                    }

                    .imageParent:nth-child(2) {
                      top: 25%;
                      left: 50%;
                    }

                    .itemImageParent {
                      position: relative;
                      left: 25%;
                      transform: skewX(0deg);
                    }

                    .featureItemContainer {
                      height: 100%;
                      flex-wrap: wrap;
                      width: calc(100% / 3);
                    }

                    .featureItem {
                      text-transform: uppercase;
                      text-align: center;
                      transform: skewX(0deg);
                      letter-spacing: normal;
                      padding: 10px 0px;
                      font-size: ${dynamicCssSize(48, 10)};
                      width: 100%;
                    }
                  }
                `}
                </style>
                <div className={"container-fluid memberContainerV2"}>
                    <div className="container">
                        <div className={"headingParent"}>
                            <h2 className={"heading"}>{membersData.headingV2}</h2>
                        </div>
                        <div className={"contentContainer row"}>
                            <div className={"memberOfContainer col-md-6 col-12"}>
                                {/* {membersData.listV2.map((item, index) => {
                                    return ( */}
                                        <div className={"imageParent"} key={"memberOfContainer_"}>
                                            <div className={"imageContainer"}>
                                                <CustomImageComponent src={membersData.image || 'https://d3g4wmezrjkwkg.cloudfront.net/website/images/indiaMemberOf.webp'} alt={"memberof"} layout={"fill"}
                                                                      objectFit={"contain"}/>
                                            </div>
                                        </div>
                                    
                                {/* })} */}
                            </div>
                            <div className={"featuresContainer col-md-6 col-12"}>
                                <div className={"featureItemParent"}>
                                    {membersData.features.map((item, index) => {
                                        return (
                                            <div className={"featureItemContainer"} key={"featureItemParent_" + index}>
                                                <div className={"itemImageParent"}>
                                                    <div className={"itemImageContainer"}>
                                                        <CustomImageComponent src={item.image.src} alt={item.image.alt}
                                                                              layout={"fill"} objectFit={"contain"}/>
                                                    </div>
                                                </div>
                                                <div className={"featureItem"} title={item.heading}>
                                                    {item.heading}
                                                </div>
                                            </div>

                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </H2TrapeziumBG> : <></>
    )
}

export default MemberOfComponentV2;